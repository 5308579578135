import {
	ForwardedRef,
	forwardRef,
	PropsWithChildren,
	ReactElement,
} from 'react';

import { classes } from '@/uiPrimitives/base/classes.helpers';

import {
	scrollInline,
	scrollInlineMeasure,
	scrollInlineContent,
	scrollInlineMasks,
	scrollNegativePadding,
	notOverflowingCSS,
} from './ScrollInline.css';
import { focusWrapper } from '@/uiPrimitives/base/focus.css';

const ScrollInline_ = (
	{
		children,
		className,
		scrollAreaClassName,
		measureBeforeSlot,
		measureAfterSlot,
		mask,
		notOverflowing,
		scrollbarWidth,
	}: PropsWithChildren<{
		className?: string;
		scrollAreaClassName?: string;
		measureBeforeSlot?: ReactElement | false;
		measureAfterSlot?: ReactElement | false;
		mask?: keyof typeof scrollInlineMasks;
		notOverflowing?: boolean;
		scrollbarWidth: keyof typeof scrollInline;
	}>,
	ref: ForwardedRef<HTMLDivElement>
) => {
	return (
		<div
			className={classes(
				className,
				focusWrapper,
				scrollNegativePadding[scrollbarWidth]
			)}
		>
			<div
				ref={ref}
				className={classes(
					notOverflowing && notOverflowingCSS,
					scrollInline[scrollbarWidth],
					mask != null && scrollInlineMasks[mask],
					scrollAreaClassName
				)}
				tabIndex={0}
			>
				<div className={classes(scrollInlineMeasure)}>
					{measureBeforeSlot}
					<div className={classes(scrollInlineContent)}>{children}</div>
					{measureAfterSlot}
				</div>
			</div>
		</div>
	);
};
export const ScrollInline = forwardRef(ScrollInline_);

export const scrollLeft = (
	scrollAreaElement: HTMLDivElement,
	scrollRatio: number
) => {
	const scrollDelta = scrollRatio * scrollAreaElement.clientWidth;

	// scrollBy has janky behaviour on iOS Safari
	scrollAreaElement.scrollTo({
		left: scrollAreaElement.scrollLeft - scrollDelta,
		behavior: 'smooth',
	});
};

export const scrollRight = (
	scrollAreaElement: HTMLDivElement,
	scrollRatio: number
) => {
	const scrollDelta = scrollRatio * scrollAreaElement.clientWidth;

	// scrollBy has janky behaviour on iOS Safari
	scrollAreaElement.scrollTo({
		left: scrollAreaElement.scrollLeft + scrollDelta,
		behavior: 'smooth',
	});
};
