'use client';

import type { Translation } from '@/globals/translate/translations';
import { classes } from '@/uiPrimitives/base/classes.helpers';
import { zIndex } from '@/uiPrimitives/layout/zIndex.css';
import { ReactNode } from 'react';
import { ScrollInline, scrollLeft, scrollRight } from './ScrollInline';
import {
	measureAfter,
	measureBefore,
	scrollInlineWrapper,
} from './ScrollInline.css';
import { useShowScrollButtons } from './useShowScrollButtons';
import { block } from '@/uiPrimitives/utility/display.css';
import { ScrollButton } from './ScrollButton';

export type ScrollInlineWithBigButtonsProps = {
	className?: string;
	scrollAreaClassName?: string;
	descriptionSlot?: ReactNode;
	scrollRatio?: number;
	children?: ReactNode;
};

export const ScrollInlineWithBigButtonsClient = ({
	children,
	className,
	scrollAreaClassName,
	beforeButtonLabel,
	afterButtonLabel,
	descriptionSlot,
	scrollRatio = 2 / 3,
}: ScrollInlineWithBigButtonsProps & {
	beforeButtonLabel: Translation<'Scroll left in previous scroll area'>;
	afterButtonLabel: Translation<'Scroll right in previous scroll area'>;
}) => {
	const {
		measureBeforeRef,
		measureAfterRef,
		scrollAreaRef,
		showBeforeButton,
		showAfterButton,
	} = useShowScrollButtons();

	return (
		<span
			className={classes(
				className,
				scrollInlineWrapper,
				zIndex({ isolation: 'isolate' })
			)}
		>
			<ScrollInline
				scrollAreaClassName={scrollAreaClassName}
				ref={scrollAreaRef}
				measureBeforeSlot={
					<i className={classes(measureBefore)} ref={measureBeforeRef} />
				}
				measureAfterSlot={
					<i className={classes(measureAfter)} ref={measureAfterRef} />
				}
				scrollbarWidth="thin"
				notOverflowing={showBeforeButton === false && showAfterButton === false}
				mask={
					showBeforeButton && showAfterButton
						? 'both'
						: showBeforeButton
						? 'before'
						: showAfterButton
						? 'after'
						: undefined
				}
			>
				<span className={classes(block)}>{children}</span>
			</ScrollInline>

			<ScrollButton.Container
				isShowing={showBeforeButton || showAfterButton}
				descriptionSlot={descriptionSlot}
			>
				<ScrollButton.Item
					side="before"
					isShowing={showBeforeButton ?? false}
					buttonLabel={beforeButtonLabel}
					onClick={() => {
						const scrollAreaElement = scrollAreaRef.current;
						if (scrollAreaElement == null) return;

						scrollLeft(scrollAreaElement, scrollRatio);
					}}
				/>
				<ScrollButton.Item
					side="after"
					isShowing={showAfterButton ?? false}
					buttonLabel={afterButtonLabel}
					onClick={() => {
						const scrollAreaElement = scrollAreaRef.current;
						if (scrollAreaElement == null) return;

						scrollRight(scrollAreaElement, scrollRatio);
					}}
				/>
			</ScrollButton.Container>
		</span>
	);
};
